<template>
  <div class="user">
    <!-- 面包屑导航 -->
    <el-card>
      <div class="menu-box">
        <h2>盘点记录</h2>
        <div class="box-bd">
          <div class="search">
            <el-form :model="query">
              <el-row>
                <el-col :span="8">
                  <el-form-item
                    label="盘点编号"
                    placeholder="请输入盘点编号"
                    prop="check_no"
                    :label-width="formLabelWidth"
                  >
                    <el-input v-model="query.check_no" />
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item
                    label="盘点仓库"
                    prop="warehouse_id"
                    :label-width="formLabelWidth"
                  >
                    <el-select
                      v-model="query.warehouse_id"
                      style="width: 100%"
                      placeholder="请选择盘点仓库"
                      clearable
                    >
                      <el-option
                        v-for="item in warehousesList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item
                    label="盘点员"
                    prop="check_user_id"
                    :label-width="formLabelWidth"
                  >
                    <el-select
                      v-model="query.check_user_id"
                      style="width: 100%"
                      placeholder="请选择盘点员"
                      clearable
                    >
                      <el-option
                        v-for="item in userList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item
                    label="盘点时间"
                    placeholder="请选择盘点时间范围"
                    :label-width="formLabelWidth"
                  >
                    <el-date-picker
                      v-model="time"
                      type="daterange"
                      style="width: 100%"
                      value-format="yyyy-MM-dd "
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                    >
                    </el-date-picker>
                  </el-form-item>
                </el-col>
                <!-- <el-col :span="8">
                  <el-form-item
                    :label-width="formLabelWidth"
                    prop="status"
                    placeholder="请选择盘点状态"
                    label="盘点状态"
                  >
                    <el-select
                      v-model="query.status"
                      style="width: 100%"
                      placeholder="请选择状态"
                      clearable
                    >
                      <el-option
                        v-for="item in statusList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col> -->
                <el-col :span="8">
                  <el-button
                    style="margin-left:60px"
                    type="primary"
                    @click="getCheckList()"
                    >条件查询</el-button
                  >
                </el-col>
              </el-row>
            </el-form>
          </div>
          <div class="record-table">
            <el-table class="rec-table" :data="recordTableData">
              <el-table-column
                prop="warehouse"
                width="150"
                label="仓库"
              ></el-table-column>
              <el-table-column
                prop="check_no"
                width="180"
                label="盘点编号"
              ></el-table-column>

              <el-table-column
                prop="add_time"
                label="盘点时间"
              ></el-table-column>
              <el-table-column
                prop="check_user"
                label="盘点员"
              ></el-table-column>
              <el-table-column prop="remark" label="备注"></el-table-column>
              <!-- <el-table-column prop="status" label="盘点状态">
                <template v-slot="scope">
                  <el-tag type="warning" v-if="scope.row.status === 0"
                    >未完成</el-tag
                  >
                  <el-tag type="success" v-if="scope.row.status === 1"
                    >已完成</el-tag
                  >
                  <el-tag type="errior" v-if="scope.row.status === 2"
                    >已拒绝</el-tag
                  >
                </template>
              </el-table-column> -->
              <el-table-column label="操作" width="200">
                <template slot-scope="scope">
                  <el-button
                    size="mini"
                    icon="el-icon-search"
                    @click="openInfo(scope.row)"
                    >查看详情</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
              @current-change="pageChange"
              @size-change="sizeChange"
              :pager-count="15"
              :page-sizes="[7, 15, 20]"
              layout="total, sizes, prev, pager, next"
              :page-size="this.query.limit"
              background
              :total="exp.num"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </el-card>

    <!-- 详情弹窗 -->

    <el-dialog width="70%" title="详情" :visible.sync="detailDiaVisible">
      <div class="info">
        <el-descriptions title="产品信息" border :column="3">
          <el-descriptions-item label="记录id">{{
            checkInfo.id
          }}</el-descriptions-item>
          <el-descriptions-item label="记录编号">{{
            checkInfo.check_no
          }}</el-descriptions-item>
          <el-descriptions-item label="盘点员">{{
            checkInfo.check_user
          }}</el-descriptions-item>
          <el-descriptions-item label="盘点仓库"
            >{{ checkInfo.warehouse }}
          </el-descriptions-item>
          <el-descriptions-item label="盘点时间"
            >{{ checkInfo.edit_time }}
          </el-descriptions-item>
          <el-descriptions-item label="备注">{{
            checkInfo.remark
          }}</el-descriptions-item>
        </el-descriptions>
      </div>
      <div class="table" style="margin-top:5px">
        <el-table
          height="400"
          class="check-table"
          :data="checkInfo.list"
          border
        >
          <el-table-column
            prop="id"
            width="120"
            label="商品id"
          ></el-table-column>
          <el-table-column prop="mp_name" label="商品名称"></el-table-column>

          <el-table-column label="规格" prop="spec"></el-table-column>

          <!-- <el-table-column label="SKU条码"></el-table-column> -->
          <el-table-column prop="store_num" label="库存"></el-table-column>
          <el-table-column prop="check_num" label="盘点数量"> </el-table-column>
        </el-table>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getCheckList, getcheckInfo } from '@/api/psi/check.js'
import { getWarehousesList, getUserlist } from '@/api/psi/storage.js'
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      detailDiaVisible: false,
      warehousesList: [],
      checkedItem: [],
      ids: [],
      userList: [],
      formLabelWidth: '120px',
      statusList: [
        { name: '已完成', id: 1 },
        { name: '未完成', id: 0 },
      ],
      query: {
        page: 1,
        limit: 7,
        page_code: 1,
        check_no: '',
        start_time: '',
        end_time: '',
        warehouse_id: '',
        check_user_id: '',
        status: '',
      },
      exp: {
        // 总条数
        num: 0,
        // 页数
        count: '',
      },
      recordTableData: [],
      time: [],
      checkInfo: {},
    }
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    async getCheckList() {
      if (this.time && this.time.length !== 0) {
        this.query.start_time = this.time[0].trim()
        this.query.end_time = this.time[1].trim()
      }
      const { data: res } = await getCheckList(this.query)
      this.recordTableData = res.data
      this.exp.num = res.exp.num
      //this.query.limit = res.exp.count
    },
    selectable(row) {
      //console.log(row)
      if (row.status === 0) {
        return true
      } else {
        return false
      }
    },
    getRowKeys(row) {
      //   console.log(row)
      // this.rowKeyArr.push(row.id)
      return row.id
    },
    async getSelectOtps() {
      // 仓库列表
      const { data: res1 } = await getWarehousesList()

      this.warehousesList = res1.data
      // 盘点员
      const { data: res2 } = await getUserlist()
      this.userList = res2.data
    },
    //修改每页数量
    sizeChange(value) {
      //console.log(value)
      this.query.limit = value
      this.getCheckList()
    },
    handleChange(val) {
      console.log(val)
      this.checkedItem = val
    },
    //分页切换
    pageChange(value) {
      //console.log(value)
      this.query.page = value
      this.getCheckList()
    },

    // 详情
    async openInfo(row) {
      this.detailDiaVisible = true
      const { data: res } = await getcheckInfo({ check_id: row.id })
      console.log(res)
      if (res.code === 1) {
        this.checkInfo = res.data
      } else {
        this.$message.error(res.$message)
      }
    },
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.getCheckList()
    this.getSelectOtps()
  },
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
}
</script>

<style scoped>
.record-table {
  margin-left: 33px;
}
.rec-table >>> .el-checkbox {
  zoom: 200% !important;
}
</style>
