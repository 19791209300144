import { post } from '@/utils/request'

//获取最新盘点编号
export function getNewCheckNo(data) {
    return post('/admin/Storage_Check/getNewCheckNo', data)
}
//库存盘点导入模板下载
export function downloadModel(data) {
    return post('/admin/Storage_Check/downloadModel', data)
}
//新增盘点

export function addCheck(data) {
    return post('/admin/Storage_Check/addCheck', data)
}
// 根据仓库获取商品列表
export function getGoodsListexport(data) {
    return post('/admin/storage_check/getGoodsList', data)
}
// 盘点记录列表
export function getCheckList(data) {
    return post('/admin/storage_check/list', data)
}
// 获取库存变动单据列表
export function getStoreDocList(data) {
    return post('/admin/storage_check/storeDocumentList', data)
}

// 盘点记录详情


export function getcheckInfo(data) {
    return post('/admin/storage_check/checkInfo', data)
}

// 变动单据详情
export function getDocumentInfo(data) {
    return post('/admin/storage_check/getDocumentInfo', data)
}